import React, {useRef, useState} from 'react';
import {Button, Confirm, Dropdown, Grid, Icon as IconS, Menu, Popup} from 'semantic-ui-react';
import Search from '../../../components/Search';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
    additionlActionsSelector,
    canDeleteSelector,
    canExportToExcelSelector,
    canImportFromExcelSelector,
    deleteRows,
    exportProgressSelector,
    exportToExcelRequest,
    gridModesCountersSelector,
    importFromExcelRequest,
    importProgressSelector,
    uploadRegistriesProgressSelector,
} from '../../../ducks/gridList';

import FieldsConfig from './representations';
import {
    getRepresentationsRequest,
    representationsSelector,
    setRepresentationRequest,
} from '../../../ducks/representations';
import Icon from '../../CustomIcon';
import {useLocation, useHistory} from 'react-router-dom';
import {generateReportRequest, printLoaderSelector} from '../../../ducks/reports';
import MultiSelect from "../../BaseComponents/MultiSelect";
import {FREE_TRANSPORTS_GRID, REGISTRATION_TC_GRID, SHIPPINGS_GRID} from "../../../constants/grids";
import {setUrlSearchParam} from "../../../utils/urlParamsHelper";
import qs from "qs";
import {confirmRequest, infoSelector, progressConfirmSelector} from "../../../ducks/gridActions";
import ConfirmationsDaysModal from "../../../containers/reports/confirmationDaysModal";
import {isFunction} from "lodash";
import ShippingsMap from "../../../containers/shippingsMap";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Header = ({
                    isCreateBtn,
                    searchValue,
                    searchOnChange,
                    counter,
                    clearFilter,
                    disabledClearFilter,
                    loadList,
                    name,
                    setSelected,
                    representationName,
                    filter,
                    goToCard,
                    width,
                    pageLoading,
                    modes,
                    selectedRows,
                    setFilter,
                    fromWhere = [],
                    whereTo = [],
                    vehicleTypes = [],
                    isReport = false,
                    newTypeLayout = false,
                    generateBtn,
                    isRefresh = false,
                    unloadRegistries,
                    representationsIsLocked = false,
                    noRepresentation = false,
                    noModes = false,
                    noPrint = false,
                    customBtns = []
                }) => {
    let query = useQuery();
    const {t} = useTranslation();

    const dispatch = useDispatch();

    const fileUploader = useRef(null);

    const location = useLocation();
    const history = useHistory();

    const isImportBtn = useSelector(state => canImportFromExcelSelector(state, name));
    const isExportBtn = useSelector(state => canExportToExcelSelector(state, name));
    const actualShippingsOnMap = useSelector(state => additionlActionsSelector(state, name, 'actualShippingsOnMap'));
    const canDelete = useSelector(state => canDeleteSelector(state, name)) && [FREE_TRANSPORTS_GRID, REGISTRATION_TC_GRID].includes(name);
    const importLoader = useSelector(state => importProgressSelector(state));
    const exportLoader = useSelector(state => exportProgressSelector(state));
    const printLoader = useSelector(state => printLoaderSelector(state));
    const uploadRegistriesProgress = useSelector(state => uploadRegistriesProgressSelector(state));
    const info = useSelector(state => infoSelector(state));
    const confirmLoader = useSelector(state => progressConfirmSelector(state));

    const isPrint = !noPrint;
    const isConfirmAvailable = info.isConfirmAvailable && Array.from(selectedRows).length;

    const representations = useSelector(state => representationsSelector(state, name));

    const counters = useSelector(gridModesCountersSelector);

    const exportExcel = () => {
        dispatch(exportToExcelRequest({name, filter: filter.filter, reportFilters: filter.reportFilters}));
    };

    const importExcel = () => {
        fileUploader && fileUploader.current.click();
    };

    const onFilePicked = e => {
        const file = e.target.files[0];

        const data = new FormData();
        data.append('FileName', file.name);
        data.append('FileContent', new Blob([file], {type: file.type}));
        data.append('FileContentType', file.type);

        dispatch(
            importFromExcelRequest({
                name,
                form: data,
                callbackSuccess: () => loadList(false, true),
            }),
        );
    };

    const getRepresentations = callBackFunc => {
        dispatch(getRepresentationsRequest({key: name, callBackFunc}));
    };

    const changeRepresentation = (key, isEdit) => {
        dispatch(
            setRepresentationRequest({
                gridName: name,
                value: key,
                callbackSuccess: () => {
                    setSelected(new Set());
                    pageLoading && pageLoading(true, true, true);
                },
            }),
        );
    };

    const handleGoToCard = () => {
        goToCard(false, null, name);
    };

    const handleItemClick = (e, {name}) => {
        history.replace(setUrlSearchParam(location, 'mode', name, false, isRefresh ? ['clearOldFilters', 'registryNumber'] : null));
    };

    const canRefresh = (location) => {
        const canRefreshModes = [
            '2', //Подтвержденные реестры
            '3', //Архивные реестры
            '4' //Перевозки в реестрах
        ]
        const {mode} = qs.parse(location.search, {ignoreQueryPrefix: true}) || {};
        return !!(canRefreshModes.includes(mode) && Array.from(selectedRows).length);
    }

    const printReport = (e, {value}) => {
        dispatch(
            generateReportRequest({
                gridName: name,
                params: {
                    type: value,
                    ids: Array.from(selectedRows),
                    isGrid: true
                },
            }),
        );
    };

    const confirmAvailable = () => {
        dispatch(
            confirmRequest({
                ids: Array.from(selectedRows),
                name: name,
                callbackSuccess: () => {
                    history.replace(setUrlSearchParam(setUrlSearchParam(location, 'clearOldFilters', true), 'mode', '2'));
                    unloadRegistries && unloadRegistries([...selectedRows]);
                    setSelected(selectedRows)
                }
            }),
        );
    };

    const [openModal, setOpenModal] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    const createBtn = () => {
        return name === FREE_TRANSPORTS_GRID ?
            <span className='d-flex text-nowrap'><IconS name='add'/>{t('addNewFreeTransport')}</span>
            : null
    }

    const onDelete = (ids) => dispatch(deleteRows({
        name,
        data: {
            ids
        },
        callbackSuccess: () => {
            setOpenDeleteConfirm(false);
            loadList(false, true);
        }
    }));

    const deleteBtn = () => {
        let btnData = {
            name: 'delete',
            content: 'delete-items-confirm',
            confirm: 'delete'
        }

        switch (name) {
            case FREE_TRANSPORTS_GRID: {
                btnData.name = 'cancelTransport';
                btnData.content = 'cancelSelectedTransportConfirmText';
                btnData.confirm = 'cancelTransport';
                break;
            }
        }

        return btnData;
    }

    const [openMap, setOpenMap] = useState(false)

    return (
        <Grid className="grid-header-panel">
            {(isReport && !newTypeLayout) && <Grid.Row columns="1" className='grid-header-panel__name'>
                {t(name)}
            </Grid.Row>}
            <Grid.Row columns="equal">
            {modes.length && !noModes ? (
                        <div style={{width: '100%', marginBottom: '20px'}}>
                            <Menu stackable compact>
                                {modes.map(item => (
                                    <Menu.Item
                                        key={item.mode}
                                        name={item.mode}
                                        active={query.get('mode') == item.mode}
                                        onClick={handleItemClick}
                                        style={{maxWidth: newTypeLayout ? '180px' : 'auto'}}
                                    >
                                        {`${item.caption}${
                                            counters[item.mode] ? ` (${counters[item.mode]})` : ''
                                        }`}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        </div>
                    ) : null}
                <Grid.Column width={newTypeLayout ? 13 : 12} style={{display: 'flex', flexWrap: newTypeLayout && 'wrap'}}>
                    {newTypeLayout && <div className='grid-header-panel__name grid-header-panel__name-in-panel'>
                                {t(name)}
                            </div>}
      

                    {!noRepresentation && <FieldsConfig
                        style={{marginTop: (newTypeLayout && !noModes) && '10px', width: newTypeLayout && '100%'}}
                        gridName={name}
                        width={width}
                        representationName={!representationsIsLocked && representationName}
                        getRepresentations={getRepresentations}
                        changeRepresentation={changeRepresentation}
                        representations={representations}
                        mode={query.get('mode')}
                        disabled={representationsIsLocked}
                    />}
                    <span className="records-counter margin-left-8" style={{marginTop: (newTypeLayout && !noRepresentation && !noModes) && '18px'}}>{t('totalCount', {count: counter})}</span>
                </Grid.Column>
                <Grid.Column width={newTypeLayout ? 3 : null} className="grid-right-elements" style={{maxHeight: newTypeLayout && "36px"}}>
                    {generateBtn && generateBtn}
                    {
                        isRefresh && <Popup
                            content={t('confirm')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon='check circle outline'
                                    disabled={!isConfirmAvailable}
                                    onClick={confirmAvailable}
                                    loading={confirmLoader}
                                />
                            }
                        />
                    }
                    {
                        isRefresh && <Popup
                            content={t('confirmationDays')}
                            position="bottom right"
                            trigger={
                                <Button
                                    onClick={() => setOpenModal(true)}
                                    icon='calendar check outline'
                                />
                            }
                        />
                    }
                    {isRefresh && canRefresh(location) && (
                        <Popup
                            content={t('downloadRegistry')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="upload"
                                    loading={uploadRegistriesProgress}
                                    onClick={unloadRegistries}
                                />
                            }
                        />
                    )}
                    {isCreateBtn && (
                        <Popup
                            content={t('add_record')}
                            position="bottom right"
                            trigger={<Button icon="add" onClick={handleGoToCard}>{createBtn()}</Button>}
                        />
                    )}

                    {
                        canDelete && <Popup
                            disabled={!(selectedRows && selectedRows.size)}
                            content={t(deleteBtn().name)}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon={name !== FREE_TRANSPORTS_GRID && 'trash alternate outline'}
                                    onClick={() => setOpenDeleteConfirm(true)}
                                    disabled={!(selectedRows && selectedRows.size)}
                                    content={name === FREE_TRANSPORTS_GRID ? <span className='d-flex text-nowrap'>
                                        <IconS name='trash alternate outline'/>
                                        {t(deleteBtn().name)}
                                    </span> : null}
                                />
                            }
                        />
                    }

                    {isPrint &&
                            <Dropdown
                                className="grid-print-btn"
                                disabled={!(selectedRows && selectedRows.size)}
                                trigger={
                                    <Popup
                                        content={t('printReport')}
                                        position="bottom right"
                                        trigger={
                                            <Button
                                                icon
                                                loading={printLoader}
                                                disabled={!(selectedRows && selectedRows.size)}
                                            >
                                                <IconS name="print"/>
                                            </Button>
                                        }
                                    />
                                }
                                value={null}
                                selectOnBlur={false}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={printReport} key={0} value={0}>{t('genericReport')}</Dropdown.Item>
                                    <Dropdown.Item onClick={printReport} key={1} value={1}>{t('forwardingReport')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }

                    {isImportBtn && (
                        <Popup
                            content={t('importFromExcel')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="upload"
                                    loading={importLoader}
                                    onClick={importExcel}
                                />
                            }
                        />
                    )}
                    {isExportBtn && (
                        <Popup
                            content={
                                t('exportToExcel')
                            }
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="download"
                                    loading={exportLoader}
                                    onClick={exportExcel}
                                />
                            }
                        />
                    )}
                    <Popup
                        content={t('reset_filters')}
                        position="bottom right"
                        trigger={
                            <Button
                                icon
                                className={`clear-filter-btn`}
                                onClick={clearFilter}
                                disabled={disabledClearFilter}
                            >
                                <Icon name="clear-filter"/>
                            </Button>
                        }
                    />

                    {
                        customBtns && customBtns.map(btn => (
                            <Popup
                                content={t(btn.name)}
                                position="bottom right"
                                trigger={
                                    <Button
                                        loading={btn.loading}
                                        icon={btn.icon}
                                        onClick={() => btn.onClick(Array.from(selectedRows), () => {
                                            setSelected(new Set());
                                        })}
                                        disabled={btn.disabled ? (isFunction(btn.disabled) ? btn.disabled(Array.from(selectedRows)) : btn.disabled) : !selectedRows.size}
                                    />
                                }
                            />
                        ))
                    }

                    {
                        canDelete && <Confirm
                            open={openDeleteConfirm}
                            content={t(deleteBtn().content)}
                            cancelButton={t('cancelConfirm')}
                            confirmButton={t(deleteBtn().confirm)}
                            onCancel={() => setOpenDeleteConfirm(false)}
                            onConfirm={() => {
                                onDelete(Array.from(selectedRows))
                            }}
                        />
                    }

                    <Search
                        searchValue={searchValue}
                        className="search-input"
                        value={filter.filter.filter.search}
                        onChange={searchOnChange}
                    />
                </Grid.Column>
            </Grid.Row>
            <input
                type="file"
                ref={fileUploader}
                style={{display: 'none'}}
                onInput={onFilePicked}
            />
            {[SHIPPINGS_GRID, FREE_TRANSPORTS_GRID].includes(name) && <Grid.Row className='form ui'>
                <Grid.Column width="4">
                    <MultiSelect
                        label={t('fromWhere')}
                        name={'fromWhere'}
                        textValue={(!fromWhere || fromWhere.length === 0) ? t('allRoute') : ''}
                        onChange={setFilter}
                        source={'zones'}
                        value={fromWhere || []}
                        isTranslate
                    />
                </Grid.Column>

                <Grid.Column width="4">
                    <MultiSelect
                        label={t('whereTo')}
                        name={'whereTo'}
                        textValue={(!whereTo || whereTo.length === 0) ? t('allRoute') : ''}
                        onChange={setFilter}
                        source={'zones'}
                        value={whereTo || []}
                        isTranslate
                    />
                </Grid.Column>

                {name === FREE_TRANSPORTS_GRID && <Grid.Column width="4">
                    <MultiSelect
                        label={t('vehicleTypeId')}
                        name={'vehicleTypes'}
                        textValue={(!vehicleTypes || vehicleTypes.length === 0) ? t('allVehicleTypes') : ''}
                        onChange={setFilter}
                        source={'vehicleTypes'}
                        value={vehicleTypes || []}
                        isTranslate
                    />
                </Grid.Column>}
                {
                    actualShippingsOnMap && <Grid.Column className='margin-bottom-10 margin-top-auto' width="4">
                        <Button id='b-color__blue' color='blue' onClick={() => setOpenMap(true)}>
                            <IconS name='map marker alternate'/> <b>{t('actualShippingsOnMap')}</b>
                        </Button>
                    <ShippingsMap open={openMap} onClose={() => setOpenMap(false)}/>
                    </Grid.Column>
                }
            </Grid.Row>}
            <ConfirmationsDaysModal setModalOpen={setOpenModal} open={openModal}/>
        </Grid>
    );
};

export default Header;