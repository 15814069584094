// const colors = {
//     blue: '#4285f4',
//     orange: '#fbbc05',
//     green: '#34a853',
//     car: "#EE3B2C"
// };
export const stopperByType = (mD) => {
    if (mD.stoppers && mD.stoppers.length) {
        switch (mD.pointType) {
            case 0:
            case 1:
                return mD.stoppers[0];
            case 2:
                return mD.stoppers[mD.stoppers.length - 1];
            default:
                return mD.stoppers[0];
        }
    }
};

export const stopperNameByType = (mD) => {
    if (mD.stoppers && mD.stoppers.length) {
        switch (mD.pointType) {
            case 0:
                return "Nowa trasa"
            case 1:
                return "Aukcja"
            case 2:
                return "Biezące"
            default:
                return ""
        }
    }
};

export const stopperColorByType = (mD) => {
    if (mD.stoppers && mD.stoppers.length) {
        switch (mD.pointType) {
            case 0:
                return "__badge--blue"
            case 1:
                return "__badge--purple"
            case 2:
                return "__badge--green"
            default:
                return "__badge--green"
        }
    }
};


// export const colorByType = (mD) => {
//     switch (mD.pointType) {
//         case 0:
//         case 1:
//             return colors.blue;
//         case 2:
//             return colors.orange;
//         default:
//             return colors.blue;
//     }
// };