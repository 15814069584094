import React, { useEffect } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';

// Fix for default markers
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const colors = {
    blue: '#4285f4',
    orange: '#fbbc05',
    green: '#34a853',
    car: "#EE3B2C",
    black: "#000"
};

const LeafletMap = ({mapData, setShipping,vehiclesData, routeData, setRouteData, isTransportCompanyUser}) => {
    const mapState = {
        center: [52.1157, 19.4013],
        zoom: 7,
    };

    const stopperByType = (mD) => {
        if (mD.stoppers && mD.stoppers.length) {
            switch (mD.pointType) {
                case 0:
                case 1:
                case 2:
                    return mD.stoppers[0];
                default:
                    return mD.stoppers[0];

            }
        }
    };

    const stopperByType2 = (mD) => {
        if (mD.stoppers && mD.stoppers.length) {
            switch (mD.pointType) {
                case 0:
                case 1:
                    return mD.stoppers[mD.stoppers.length - 1];
                case 2:
                    return mD.stoppers[mD.stoppers.length - 1];
                default:
                    return mD.stoppers[mD.stoppers.length - 1];
            }
        }
    };

    const colorByType = (mD) => {
        switch (mD.pointType) {
            case 0:
            case 1:
                return colors.blue;
            case 2:
                return colors.green;
            default:
                return colors.blue;
        }
    };

    const createCustomIcon = (color) => {
        if (color === colors.car) {
            return L.divIcon({
                className: 'custom-div-icon',
                html:  `<svg width="27" height="27" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#EE3B2C"/>
                            <path d="M19.6 13.8H19.2V11.0975C19.2 10.78 19.0725 10.475 18.8475 10.25L16.35 7.7525C16.125 7.5275 15.82 7.4 15.5025 7.4H14.4V6.2C14.4 5.5375 13.8625 5 13.2 5H5.2C4.5375 5 4 5.5375 4 6.2V14.2C4 14.8625 4.5375 15.4 5.2 15.4H5.6C5.6 16.725 6.675 17.8 8 17.8C9.325 17.8 10.4 16.725 10.4 15.4H13.6C13.6 16.725 14.675 17.8 16 17.8C17.325 17.8 18.4 16.725 18.4 15.4H19.6C19.82 15.4 20 15.22 20 15V14.2C20 13.98 19.82 13.8 19.6 13.8ZM8 16.6C7.3375 16.6 6.8 16.0625 6.8 15.4C6.8 14.7375 7.3375 14.2 8 14.2C8.6625 14.2 9.2 14.7375 9.2 15.4C9.2 16.0625 8.6625 16.6 8 16.6ZM16 16.6C15.3375 16.6 14.8 16.0625 14.8 15.4C14.8 14.7375 15.3375 14.2 16 14.2C16.6625 14.2 17.2 14.7375 17.2 15.4C17.2 16.0625 16.6625 16.6 16 16.6ZM18 11.4H14.4V8.6H15.5025L18 11.0975V11.4Z" fill="#EE3B2C"/>
                        </svg>`,
                iconSize: [20, 20],
                iconAnchor: [7, 7]
            });
        }

        return L.divIcon({
            className: 'custom-div-icon',
            html: `<div style="background-color: ${color}; width: 20px; height: 20px; border-radius: 50%; border: 2px solid white; box-shadow: 0 0 0 2px ${color};"></div>`,
            iconSize: [20, 20],
            iconAnchor: [7, 7]
        });
    };


    useEffect(() => {
        if (!routeData || !routeData.stoppers || !routeData.stoppers.length) return;
    
        const container = L.DomUtil.get('map');
        if (container != null) {
            container._leaflet_id = null;
        }
    
        const map = L.map('map').setView(mapState.center, mapState.zoom);
        
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            minZoom: 0,
            maxZoom: 20,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
    
        const waypoints = routeData.stoppers.map(stopper => 
            L.latLng(stopper.latitude, stopper.longitude)
        );

        waypoints?.forEach((waypoint, i) => {
            // Check if waypoint is already in [lat, lng] format
            const position = Array.isArray(waypoint) ? waypoint : 
                            waypoint.latLng ? waypoint.latLng :
                            [waypoint.lat, waypoint.lng];
                            
            const color = i === 0 ? colors.blue : 
                         i === waypoints.length - 1 ? colors.green : 
                         colors.black;
            
            L.marker(position, {
                icon: createCustomIcon(color)
            }).addTo(map);
        });
    
        // Add mocked vehicle marker near the last waypoint
        // const lastWaypoint = waypoints[waypoints.length - 1];
        // L.marker(
        //     [
        //         lastWaypoint.lat + (Math.random() - 0.5),
        //         lastWaypoint.lng + (Math.random() - 0.5)
        //     ],
        //     {
        //         icon: createCustomIcon(colors.car)
        //     }
        // ).bindPopup(`
        //     <div>
        //         <div>Nr rejestracyjny: WWA 11234</div>
        //         <div>Data ost. pozycji: ${
        //             new Date().toLocaleString('pl-PL', {
        //                 day: '2-digit',
        //                 month: '2-digit',
        //                 year: 'numeric',
        //                 hour: '2-digit',
        //                 minute: '2-digit'
        //             }).replace(',', '')
        //         }</div>
        //     </div>
        // `).addTo(map);

        // Temporary solution for adding vehicles to the map. Waiting for list of route vehicles
        vehiclesData && vehiclesData?.result?.forEach((vehicle) => {
        L.marker(
            [
                vehicle.latitude,
                vehicle.longitude 
            ],
            {
                icon: createCustomIcon(colors.car)
            }
        ).bindPopup(`
            <div>
                <div>Nr rejestracyjny: §${vehicle.licensePlate}</div>
                <div>Data ost. pozycji: ${
                    new Date(vehicle.positionTimestamp).toLocaleString('pl-PL', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    }).replace(',', '')
                }</div>
            </div>
        `).addTo(map);
            })
    
        return () => {
            map.remove();
        };
        
    }, [routeData]);

    return (
        <div className="map-modal__map">
            {!routeData ? (
                <Map 
                    {...mapState}
                    style={{ height: '100%' }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MarkerClusterGroup>
                        {mapData && mapData.map((mD) => {
                            const stopper = stopperByType(mD);

                            const stopper2 = stopperByType2(mD);



                            if (!stopper) return null;
                            
                            return (
                                <React.Fragment key={mD.id}>
                                    <Marker
                                        key={mD.id}
                                        position={[stopper.latitude, stopper.longitude]}
                                        icon={createCustomIcon(colors.blue)}
                                        onClick={() => {
                                            setShipping(mD);
                                            setRouteData(mD);
                                        }}
                                        onMouseOver = {event => event.target.openPopup()}
                                    >
                                        <Popup>
                                            <div>
                                                №{mD.shippingNumber} 
                                                {mD.pointType !== 2 
                                                    ? ` - ${mD.cost} ₽` 
                                                    : (isTransportCompanyUser 
                                                        ? '' 
                                                        : ` - ${mD.codeFmTransortCompany} | ${mD.codeFmVehicleType}`)
                                                }
                                            </div>
                                        </Popup>
                                    </Marker>

                                    <Marker
                                        key={mD.id}
                                        position={[stopper2.latitude, stopper2.longitude]}
                                        icon={createCustomIcon(colors.green)}
                                        onClick={() => {
                                            setShipping(mD);
                                            // mD.pointType !== 2 && setRouteData(mD);
                                            setRouteData(mD);
                                        }}
                                        onMouseOver = {event => event.target.openPopup()}
                                    >
                                        <Popup>
                                            <div>
                                                №{mD.shippingNumber} 
                                                {mD.pointType !== 2 
                                                    ? ` - ${mD.cost} ₽` 
                                                    : (isTransportCompanyUser 
                                                        ? '' 
                                                        : ` - ${mD.codeFmTransortCompany} | ${mD.codeFmVehicleType}`)
                                                }
                                            </div>
                                        </Popup>
                                    </Marker>
                                    {/* Add mocked vehicle marker near the first coordinate */}
                                 
                                </React.Fragment>
                            );
                        })}
                        {
                            vehiclesData && vehiclesData?.result?.map((vehicle) => {
                                return    <Marker
                                key={vehicle.id}
                                position={[
                                    vehicle.latitude,
                                    vehicle.longitude
                                  ]}
                                icon={createCustomIcon(colors.car)}
                            >
                                <Popup>
                                    <div>
                                        <div>Nr rejestracyjny: {vehicle.licensePlate}</div>
                                        <div>Data ost. pozycji: {
                                            new Date(vehicle.positionTimestamp).toLocaleString('pl-PL', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            }).replace(',', '')
                                        }</div>
                                    </div>
                                </Popup>
                            </Marker>
                            })
                        }
                    </MarkerClusterGroup>
                </Map>
            ) : (
                <div id="map" style={{ height: '100%' }}></div>
            )}
        </div>
    );
};

export default LeafletMap;