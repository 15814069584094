import { all, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { postman } from '../utils/postman';

const GET_SHIPPINGS_MAP_DATA_REQUEST = 'GET_SHIPPINGS_MAP_DATA_REQUEST';
const GET_SHIPPINGS_MAP_DATA_SUCCESS = 'GET_SHIPPINGS_MAP_DATA_SUCCESS';
const GET_SHIPPINGS_MAP_DATA_ERROR = 'GET_SHIPPINGS_MAP_DATA_ERROR';

const GET_SHIPPINGS_MAP_DEFAULT_FILTERS_REQUEST = 'GET_SHIPPINGS_MAP_DEFAULT_FILTERS_REQUEST';
const GET_SHIPPINGS_MAP_DEFAULT_FILTERS_SUCCESS = 'GET_SHIPPINGS_MAP_DEFAULT_FILTERS_SUCCESS';
const GET_SHIPPINGS_MAP_DEFAULT_FILTERS_ERROR = 'GET_SHIPPINGS_MAP_DEFAULT_FILTERS_ERROR';

const GET_SHIPPINGS_MAP_VEHICLES_REQUEST = 'GET_SHIPPINGS_MAP_VEHICLES_REQUEST';
const GET_SHIPPINGS_MAP_VEHICLES_SUCCESS = 'GET_SHIPPINGS_MAP_VEHICLES_SUCCESS';
const GET_SHIPPINGS_MAP_VEHICLES_ERROR = 'GET_SHIPPINGS_MAP_VEHICLES_ERROR';

const initial = {
    data: [],
    progress: false,
    defaultFilters: {},
    vehicles: [],
};

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_SHIPPINGS_MAP_DATA_REQUEST:
        case GET_SHIPPINGS_MAP_DEFAULT_FILTERS_REQUEST:
        case GET_SHIPPINGS_MAP_VEHICLES_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_SHIPPINGS_MAP_DATA_SUCCESS:
            return {
                ...state,
                progress: false,
                data: payload,
            };
        case GET_SHIPPINGS_MAP_DEFAULT_FILTERS_SUCCESS:
            return {
                ...state,
                progress: false,
                defaultFilters: payload,
            };
        case GET_SHIPPINGS_MAP_VEHICLES_SUCCESS:
            return {
                ...state,
                progress: false,
                vehicles: payload,
            };
        case GET_SHIPPINGS_MAP_DATA_ERROR:
        case GET_SHIPPINGS_MAP_DEFAULT_FILTERS_ERROR:
        case GET_SHIPPINGS_MAP_VEHICLES_ERROR:
            return {
                ...state,
                progress: false,
            };
        default:
            return state;
    }
};

export const getShippingsMapDataRequest = payload => ({
    type: GET_SHIPPINGS_MAP_DATA_REQUEST,
    payload,
});

export const getDefaultFiltersRequest = () => ({
    type: GET_SHIPPINGS_MAP_DEFAULT_FILTERS_REQUEST,
});

export const getShippingsMapVehiclesRequest = () => ({
    type: GET_SHIPPINGS_MAP_VEHICLES_REQUEST,
});

const stateSelector = state => state.maps;

export const progressSelector = createSelector(stateSelector, state => state.progress);
export const mapDataSelector = createSelector(stateSelector, state => state.data);
export const defaultFiltersSelector = createSelector(stateSelector, state => state.defaultFilters);
export const vehiclesSelector = createSelector(stateSelector, state => state.vehicles);

function* getShippingsMapDataSaga({ payload }) {
    try {
        const result = yield postman.post(`/shipping/maps/get`, payload.filters);
        if (!(result || []).length && payload.arrayIsEmptyCallback) payload.arrayIsEmptyCallback();
        yield put({
            type: GET_SHIPPINGS_MAP_DATA_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_SHIPPINGS_MAP_DATA_ERROR,
            payload: e,
        });
    }
}

function* getDefaultFiltersSaga() {
    try {
        const result = yield postman.get(`/shipping/maps/defaultFilter`);
        yield put({
            type: GET_SHIPPINGS_MAP_DEFAULT_FILTERS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_SHIPPINGS_MAP_DEFAULT_FILTERS_ERROR,
            payload: e,
        });
    }
}

function* getShippingsMapVehiclesSaga() {
    try {
        const result = yield postman.get(`/shipping/maps/vehicles`);
        yield put({
            type: GET_SHIPPINGS_MAP_VEHICLES_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_SHIPPINGS_MAP_VEHICLES_ERROR,
            payload: e,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_SHIPPINGS_MAP_DATA_REQUEST, getShippingsMapDataSaga),
        takeEvery(GET_SHIPPINGS_MAP_DEFAULT_FILTERS_REQUEST, getDefaultFiltersSaga),
        takeEvery(GET_SHIPPINGS_MAP_VEHICLES_REQUEST, getShippingsMapVehiclesSaga),
    ]);
}
